import { ReuploadActionUnions, ReuploadActionTypes } from '../actions/reupload.action';
import { ReuploadResponse } from '../../models/reupload';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/constants';
// import { Constants, CurrentActions } from 'src/app/constants';
export interface State {
    errorText: string;
    reuploadProfile: ReuploadResponse | any;
}

export const initialState: State = {
    errorText: null,
    reuploadProfile: null,
  };


export function reducer(state = initialState, action: ReuploadActionUnions): State {
  switch (action.type) {
    case ReuploadActionTypes.UpdateKycImage:
    case ReuploadActionTypes.FetchProfile: {
      return {
        ...state,
        errorText: null
      };
    }
    case ReuploadActionTypes.FetchProfileSuccess:
    case ReuploadActionTypes.UpdateKycImageSuccess: {
      // if (action.payload.reupload[Constants.REUPLOAD_STATUS] === Constants.SUCCESSFUL_STATUS) {
      //   window.location.href = environment.sdk_base_url
      //   + action.payload.reupload[Constants.PARTNER_CODE].toLowerCase() +
      //   '/' + action.payload.reupload[Constants.MOBILE];
      // }
      return {
        ...state,
        errorText: null,
        reuploadProfile: action.payload.reupload,
      };
    }
    case ReuploadActionTypes.FetchProfileFailure:
    case ReuploadActionTypes.UpdateKycImageFailure: {
      return {
        ...state,
        errorText: action.payload.error.error
      };
    }
    default: {
      return state;
    }
  }
}

export const getErrorText = (state: State) => state.errorText;
export const getProfile = (state: State) => state.reuploadProfile;
